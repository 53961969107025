import React from "react";
import LogoHeader from "../components/logoHeader";
import PaymentGateway from "../components/payment/paymentComponent";
// import CustomModal from "../components/customModal";
import Footer from "../components/footer";
import { useAppDispatch } from "@/lib/hooks";
import { decremented } from "@/lib/features/slices/screensSlice";

const PaymentSuccess = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="wrapper">
      <div className="content payment-success center-page">
        <div className="payment-success-card">
          <div
            className="flex justify-center"
            onClick={() => dispatch(decremented())}
          >
            <img src={"./images/success-icon.svg"} />
          </div>
          <h2>Payment Success!</h2>
          <h3>€200</h3>
          <hr className="horizontal-line" />
          <div>
            <ul>
              <li className="flex justify-between mt-4 mb-4">
                <p>Ref Number</p>
                <span>000085752257</span>
              </li>
              <li className="flex justify-between mt-4 mb-4">
                <p>Payment Time</p>
                <span>25-02-2023, 13:22:16</span>
              </li>
              <li className="flex justify-between mt-4 mb-4">
                <p>Payment Method</p>
                <span>Visa ending in 2345</span>
              </li>
              <hr className="dashed-horizontal-line" />
              <li className="flex justify-between mt-4 mb-4">
                <p>1 x Lovegame for 2 persons:</p>
                <span> €200 incl vat</span>
              </li>
            </ul>
          </div>
        </div>
        <span className="mb-4 mt-4">
          We will send a copy of the receipt to your email shortly.
        </span>{" "}
      </div>
      <Footer singleBtnDark="Go to the App Store" />
    </div>
  );
};

export default PaymentSuccess;
